<template>
  <FormWrapper :is-loading="isLoading">
    <PageTitle title="Add/Update Event" slot="title" link="/helpContent/addEvent" />

    <ValidationObserver ref="validator">
 
      <FormRow>
        <div class="col-sm-2">
          <!-- <FormCheckbox
          v-model="form.isHeader"
          >
            Is Header Event?
          </FormCheckbox> -->
        <div>
          <input type="radio" id="0" value="0"  v-model="form.isHeader" />
<label for="0">  Is Calander. Event?</label>


        </div>

        </div>
        <div class="col-sm-2">
    
        <div>
          <input type="radio" id="1" value="1"  v-model="form.isHeader" />
<label for="1">  Is Header Event?</label>


        </div>

        </div>
        <div class="col-sm-2">
 
        <div>


<input type="radio" id="2" value="2"  v-model="form.isHeader" />
<label for="2">  Is Company Event?</label>
        </div>

        </div>
      </FormRow>
      <FormRow>
        <div class="col-sm-3">
          <InputText
            label="Event Name"
            v-model="form.eventName"
            rules="required"   />
        </div>
        <div class="col-sm-5">
          <InputTextArea
            v-model="form.eventDetails"
            label="Event Details" 
            maxlength="1000"
            rows="2"
            rules="required" 
          />
        </div>
      
      </FormRow>
      <FormRow>
        <!-- <div class="col-sm-2" v-if="!form.isHeader">
          <inputDate label="Event Date"
          v-model="form.eventDate"
          rules="required"
           />
        </div> -->
        <div class="col-sm-2" v-if="form.event==0">
          <inputDate label="Event Date"
          v-model="form.eventDate"
          rules="required"
           />
        </div>
        <div class="col-sm-2" v-if="form.event==1">
          <inputDate label="Date From"
          v-model="form.eventDateFrom"
          rules="required"
           />
        </div>
        <div class="col-sm-2" v-if="form.event==1">
          <!-- <inputDate label="Date To"
          v-model="form.eventDateTo"
          rules="required"
           /> -->
           <!-- sagar 05-09-2023 changes previous date disable -->
           <InputDate label="Date To"  :start-date="Date.now() - 1*24*60*60*1000" rules="required" :options="options" v-model="form.eventDateTo" />
        </div>
        <!-- <div class="col-sm-3" v-if="form.isHeader">
            <div :class="$style.imageFile">
                <div :class="$style.imageFileLabel">
                    <span>Header Image</span>
                </div>
                <div :class="$style.imageFileInput">
                    <InputFile :rules="{required:isrequired, docCheck:'jpg,jpeg,png', checkFileRegex: true}" :value="form.image" name="Icon" label="Choose File" @onChange="uploadFileHandler"  required/>
                </div>
            </div>
        </div> -->
        <div class="col-sm-3" v-if="form.event==1">
            <div :class="$style.imageFile">
                <div :class="$style.imageFileLabel">
                    <span>Header Image</span>
                </div>
                <div :class="$style.imageFileInput">
                    <InputFile :rules="{required:isrequired, docCheck:'jpg,jpeg,png', checkFileRegex: true}" :value="form.image" name="Icon" label="Choose File" @onChange="uploadFileHandler"  required/>
                </div>
            </div>
        </div>
      </FormRow>
      <FormRow v-if="form.event==2">
        <div class="col-sm-3">
          <InputText
            label="Company Name"
            v-model="form.companyName"
            rules="required"   />
        </div>
        <div class="col-sm-2" >
          <inputDate label="Event Date"
          v-model="form.eventDate"
          rules="required"
           />
        </div>
  
      </FormRow>
      <FormRow v-if="form.event==2">
        <div class="col-sm-2" >
        
       
        <span>Event Images</span>
 
</div>
<div class="col-sm-10" >
  <div :class="$style.files">
      <!-- <fileUpload 
v-model="fileUploadArr"
/> -->

        <div :class="$style.upload">
            <div :class="$style.uploadArea">
                Drop the translation files here!<br>(Allowed: .jpg, .jpeg, .pdf)
            </div>
            <input type="file" name="Bulk" multiple="multiple" :rules="{docType: 'jpg,pdf,jpeg,zip'}" @change="bulkUploadHandler"/>
        </div>
        <div :class="$style.uploadedFiles">
            <div v-for="(file, i) in bulkUploadFiles.files" :class="$style.bulkFile" :key="i">
                {{ file.name?file.name:file }}
                <span :class="$style.removeBulkFile" @click="() => removeBulkFile(i)">
                  <img :src="require(`Assets/images/del.png`)"  />
                    <!-- <img :src="require(`Assets/images/del.png`)" height="15px" /> -->
                </span>
            </div>
        </div>
      </div>
    <!-- <div :class="$style.progress">
        <progress max="100" :value="bulkUploadFiles.progress" />
    </div> -->
        <!-- <InputFile :rules="{required:isrequired, docCheck:'jpg,jpeg,png', checkFileRegex: true}" :value="form.form.companyEventImages" name="Icon" label="Choose File" @onChange="uploadCompanyImages"  required/> -->
</div>
      </FormRow>
    </ValidationObserver>
    <br />
    <FormRow>
      <div class="col-sm-6">
        <ButtonGroup>
          <FormButton type="info" @click="()=>{$router.go(-1)}">Back</FormButton>
          <FormButton type="success" @click="sendData()">Save</FormButton>
        </ButtonGroup>
      </div>
    </FormRow>
    <Popup
      title="Success"
      type="success"
      :value="showMessage.isVisible"
      @close="() => (showMessage.isVisible = false)"
      :closable="true"
    >
      {{ showMessage.message }}
      <div slot="footer">
        
      <FormButton
        
        @click="()=>{$router.go(-1)}"
          >Ok</FormButton
        ></div>
    </Popup>
    <Popup
      title="Information"
      type="info"
      :value="infoMessage.isVisible"
      @close="() => (infoMessage.isVisible = false)"
      :closable="true"
    >
      {{ infoMessage.message }}
      <div slot="footer">
        
        <FormButton
          
          @click="()=>{infoMessage.isVisible = false}"
            >Ok</FormButton
          ></div>
    </Popup>
  </FormWrapper>
</template>

<script>
import FormWrapper from "Components/form/FormWrapper";
import FormCheckbox from 'Components/form/FormCheckbox';
import PageTitle from "Components/layout/PageTitle";
import loadingMixin from "Mixins/loadingMixin";
import { getEventList, addUpdateEventList } from "../api";
import InputDate from "Components/form/InputDate";
import { ValidationObserver } from "vee-validate";
import moment from 'moment';
import config from "Config/base";
export default {
  name: "addUpdate",
  mixins: [loadingMixin],
  components: {
    InputDate,
    FormWrapper,
    // FormCheckbox,
    PageTitle,
    ValidationObserver,

  },
  data() {
    return {
      showMessage: {
        isVisible: false,
      },
      infoMessage: {
        isVisible: false,
      },
      form: {
        eventName : "",
        eventDetails: "",
        isHeader: false,
        isCompanyEvent:false,
        event:0,
        eventDate: null,
        eventDateFrom: null,
        eventDateTo: null,
        image: null,
        companyName:"",
        imagePath:[],
      },
      isrequired: true,
      bulkUploadFiles: {
                    files: [],
                    fileXSLS: null,
                    isLoading: false,
                    total: 0,
                    success: 0,
                    fail: 0,
                    responseFileLink: '',
                    isPristine: true,
                    progress: 0 ,
                    wereFilesUploaded: false,
                    EntityType: null,
                },
options: {
disabledDate(date) {
  return date && date.valueOf() < Date.now() - 1*24*60*60*1000;
  }
},
    };
  },
  watch:{
'form.isHeader':function(val){
this.form.event=val;

},
  },
  created() {
    this.getDataByFilter();
  },
  methods: {
            resetBulkUploadData() {
                this.bulkUploadFiles = {
                        files: [],
                        fileXSLS: null,
                        isLoading: false,
                        total: 0,
                        success: 0,
                        fail: 0,
                        responseFileLink: '',
                        isPristine: true,
                        progress: 0,
                    
                };
                this.successJSON = []
            },

            bulkUploadHandler(e) {
                this.bulkUploadFiles.files = [
                    ...this.bulkUploadFiles.files,
                    ...e.target.files
                ]
            },

            removeBulkFile(index) {
this.form.imagePath.splice(index, 1)
                this.bulkUploadFiles.files.splice(index, 1)
            },
    getDataByFilter() {
      if(this.$route.params.id){
        // this.isrequired=false;
          setTimeout(() => {
              this.showLoader();
              const filters = {};
              filters.eventId = atob(this.$route.params.id);
              filters.isHeader = atob(this.$route.params.isHeader);
              const data = {
                  filterjson: {
                      filter: [filters]
                  }
              };
              getEventList(data)
                  .then(this.handleResponse)
                  .catch(this.handleError)
          },
          50
          )
      }
    },
    uploadFileHandler(file) {
      console.log(file)
        this.form.image = file;
    },
    sendData(){
        this.$refs.validator.validate().then(result=>{
            if(result){
                this.showMessage.isVisible=false;
                const formData = new FormData();
                formData.append('eventId', this.$route.params.id?atob(this.$route.params.id):0);
                formData.append('eventName', this.form.eventName);
                formData.append('eventDetails', this.form.eventDetails);
                formData.append('isHeader', this.form.isHeader);
                if(this.form.event==1){
                  formData.append('eventDateFrom', this.form.eventDateFrom);
                  formData.append('eventDateTo', this.form.eventDateTo);
                  formData.append('image', this.form.image);
                } else if(this.form.event==2){
                  formData.append('companyName', this.form.companyName);
                  formData.append('eventDate', this.form.eventDate);
                  formData.append('imagePath', this.form.imagePath.join(","));
                  this.bulkUploadFiles.files.forEach((item, i) => {
          //  if() c
          // console.log(item.name)
          // try {
          //   if (item.split(".")[1] || item.split(".")[1]=='JPG' || item.split(".")[1]=='jpg' || item.split(".")[1]=='PNG' || item.split(".")[1]=='png' || item.split(".")[1]=='JPEG' || item.split(".")[1]=='jpeg') {
                      
          //           }
          //           else{
            if (item.name) {
              formData.append('files[' + i + ']', item);
            }
                    
                    // }
          // } catch (error) {
          //   console.log(i);
          //   formData.append('files[' + i + ']', item);
          // }
                   
                  
                });
                  // formData.append('companyEventImages', this.fileUploadArr.toString());
                }
                else{
                  formData.append('eventDate', this.form.eventDate);
                }
                addUpdateEventList(formData).then((res)=>{
                    this.form = {};
                    this.showMessage.isVisible=true;
                    this.showMessage.message=res.data[0].message;
                });
            }
        })
    },
    handleResponse(response) {
        this.hideLoader();
        this.form.eventName = response.data[0].Event_Name;
        this.form.eventDetails = response.data[0].EventDetails;
        this.form.isHeader = response.data[0].IsHeaderEvent?response.data[0].IsHeaderEvent:0;
        this.form.eventDate = response.data[0].Event_Date;
        this.form.eventDateFrom = response.data[0].HeaderFrom;
        this.form.eventDateTo = response.data[0].HeaderTo;
        this.form.companyName = response.data[0].CompanyName;
      
        if (response.data[0].IsHeaderEvent==2) {
          let temp=[];
          let temp1=[]
          response.data[0].ImagePath.split(",").forEach(e => {
       console.log(e.replace(/["']/g, "").split("/").pop()) 
   temp.push(e.replace(/["']/g, "").split("/").pop())
   temp1.push(e);
     
      });
      this.form.imagePath=temp1;
          this.bulkUploadFiles.files= temp
        } else {
          
        }
      
    },
  },
};
</script>
<style lang="scss" module>
    .imageFile {
        display: flex;
        padding: 5px 0;
    }

    .imageFileLabel {
        width: 100px;
        flex-shrink: 0;
        flex-grow: 0;
        align-items: center;
        display: flex;
    }

    .imageFileInput {
        flex-grow: 1;
    }
    textarea{
        height: 180px !important;
    }
    .uploadedFiles {
        background: rgb(241, 241, 241);
        height: 200px;
        width: calc(100%/2 - 8px);
        padding: 10px 15px;
        border-radius: 4px;
        overflow: auto;
    }

    .bulkFile {
        display: flex;
        border-bottom: 1px solid darken(rgb(241, 241, 241), 10%);
        align-items: center;
        &:last-child {
            border-bottom: none;
        }
    }

    .removeBulkFile {
        cursor: pointer;
        padding: 3px;
        margin-left: auto;
    }

    .excelFile {
        display: flex;
        padding: 5px 0;
    }

    .excelFileLabel {
        width: 120px;
        flex-shrink: 0;
        flex-grow: 0;
        align-items: center;
        display: flex;
    }

    .excelFileInput {
        flex-grow: 1;
    }

    .progress {
        margin: 0 20px;
        progress {
            width: 100%;
        }
    }

    .upload {
        width: calc(100%/2 - 8px);
        height: 200px;
        position: relative;
        border-radius: 4px;
        overflow: hidden;
        &:hover {
            .uploadArea {
                background: darken(#f2f2f2, 5%);
                border: 2px dashed  darken(#e3e3e3, 5%);
            }
        }
        .uploadArea {
            position: absolute;
            pointer-events: none;
            background: #f2f2f2;
            border: 2px dashed #e3e3e3;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
            outline: none;
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: center;
        }
        input {
            width: 100%;
            height: 100%;
        }
    }

    .files {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
    }

</style>